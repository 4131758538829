/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, getDoc, Firestore } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPenToSquare, faX, faXmark } from '@fortawesome/free-solid-svg-icons'
import Modal from './secondary/Modal';
import { Spinner } from 'react-bootstrap';
import Alertas from './secondary/Alertas';
import { useNavigate } from "react-router-dom";
import { compare } from 'bcryptjs-react';

interface Idea {
    id: string;
    idea: string;
}

const Ideas = ({ db }: { db: Firestore }) => {
    const [idea, setIdea] = useState('');
    const [category, setCategory] = useState('Comida');
    const [comidaIdeas, setComidaIdeas] = useState<Idea[]>([]);
    const [actividadesIdeas, setActividadesIdeas] = useState<Idea[]>([]);
    const [isEditingCom, setIsEditingCom] = useState(false);
    const [isEditingAct, setIsEditingAct] = useState(false);
    const [comidaCompletadas, setComidaCompletadas] = useState<Idea[]>([]);
    const [actividadesCompletadas, setActividadesCompletadas] = useState<Idea[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [modalAction, setModalAction] = useState<'save' | 'edit-com' | 'edit-act'>('save');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error');
    const navigate = useNavigate();

    const guardarIdea = async () => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setModalAction('save');
            setIsModalOpen(true);
            return;
        }

        if (!idea) {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, ingresa una idea.');
            return;
        }

        try {
            await addDoc(collection(db, category), { idea });
            setIdea('');
            
            if (comidaIdeas.length === 0) {
                setIsEditingCom(false);
            } else if (actividadesIdeas.length === 0) {
                setIsEditingAct(false);
            }

            mostrarIdeas();
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Idea guardada correctamente.');
        } catch (error) {
            console.error('Error guardando la idea: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error guardando la idea.');
        }
    };

    const verificarContraseña = async () => {
        const docRef = doc(db, 'config', 'passwordDoc');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const storedPassword = docSnap.data().password;
            if (!password) {
                setAlertOpen(true);
                setAlertVariant('info');
                setAlertMessage('Por favor, introduce una contraseña.');
                return;
            }

            setLoading(true);
            const isMatch = await compare(password, storedPassword);
            if (isMatch) {
                sessionStorage.setItem('hasAccess', 'true');
                setIsModalOpen(false);
                if (modalAction === 'save') {
                    guardarIdea();
                } else {
                    toggleEdit(modalAction);
                }
                setPassword('');
                setAlertOpen(true);
                setAlertVariant('success');
                setAlertMessage('Contraseña correcta. ¡Bienvenido!');
            } else {
                setAlertOpen(true);
                setAlertVariant('error');
                setAlertMessage('Contraseña incorrecta. Por favor, inténtalo de nuevo.');
            }
        } else {
            console.error('No se encontró el documento de contraseña.');
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('No se encontró el documento de contraseña.');
        }
        setLoading(false);
    };

    const eliminarIdea = async (category: string, id: string) => {
        try {
            await deleteDoc(doc(db, category, id));
            mostrarIdeas();
            setAlertOpen(true);
            setAlertVariant('success');
            var tipoIdea = category === 'Actividades' ? 'Actividad' : category;
            setAlertMessage(tipoIdea + ' eliminada correctamente.');
        } catch (error) {
            console.error('Error eliminando la idea: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error eliminando la idea.');
        }
    };

    const completarIdea = async (category: string, id: string, idea: string) => {
        const completadaCategory = category === 'Comida' ? 'Comidas Completadas' : 'Actividades Completadas';
        try {
            await addDoc(collection(db, completadaCategory), { idea });
            await deleteDoc(doc(db, category, id));
            mostrarIdeas();
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('La idea "' + idea + '" se ha completado con éxito.');
        } catch (error) {
            console.error('Error completando la idea: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error completando la idea.');
        }
    };

    const mostrarIdeas = async () => {
        setLoading(true);
        try {
            const comidaSnapshot = await getDocs(collection(db, 'Comida'));
            const comidaList: Idea[] = [];
            comidaSnapshot.forEach(doc => {
                comidaList.push({ id: doc.id, idea: doc.data().idea });
            });
            setComidaIdeas(comidaList);

            const actividadesSnapshot = await getDocs(collection(db, 'Actividades'));
            const actividadesList: Idea[] = [];
            actividadesSnapshot.forEach(doc => {
                actividadesList.push({ id: doc.id, idea: doc.data().idea });
            });
            setActividadesIdeas(actividadesList);

            const comidaCompletadasSnapshot = await getDocs(collection(db, 'Comidas Completadas'));
            const comidaCompletadasList: Idea[] = [];
            comidaCompletadasSnapshot.forEach(doc => {
                comidaCompletadasList.push({ id: doc.id, idea: doc.data().idea });
            });
            setComidaCompletadas(comidaCompletadasList);

            const actividadesCompletadasSnapshot = await getDocs(collection(db, 'Actividades Completadas'));
            const actividadesCompletadasList: Idea[] = [];
            actividadesCompletadasSnapshot.forEach(doc => {
                actividadesCompletadasList.push({ id: doc.id, idea: doc.data().idea });
            });
            setActividadesCompletadas(actividadesCompletadasList);
        } catch (error) {
            console.error('Error obteniendo las ideas: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error obteniendo la idea.');
        } finally {
            setLoading(false);
        }
    };

    const toggleEdit = (category: string) => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setModalAction(category === 'edit-com' ? 'edit-com' : 'edit-act');
            setIsModalOpen(true);
            return;
        }

        if (category === "edit-com") {
            setIsEditingCom(prev => !prev);
        } else if (category === "edit-act") {
            setIsEditingAct(prev => !prev);
        }

    };

    useEffect(() => {
        mostrarIdeas();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid main">
                {loading && (
                    <div className="spinner-overlay">
                        <Spinner className="custom-spinner" animation="border" />
                    </div>
                )}
                
                <div className="text-center">
                    <h1 className="pb-3">Lluvia de Ideas</h1>
                </div>

                <div className="container-fluid content pb-3">
                    <form onSubmit={(e) => { e.preventDefault(); guardarIdea(); }}>
                        <div className="input-group">
                            <label htmlFor="idea">Ingresa tu idea</label>
                            <input
                                type="text"
                                id="idea"
                                value={idea}
                                onChange={e => setIdea(e.target.value)}
                                style={{marginRight: '0'}}
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="category">Selecciona la categoría</label>
                            <select
                                id="category"
                                value={category}
                                onChange={e => setCategory(e.target.value)}
                            >
                                <option value="Comida">Comida</option>
                                <option value="Actividades">Actividades</option>
                            </select>
                        </div>
                        <button className="botonPrimario" type="submit">Guardar Idea</button>
                    </form>
                    {comidaIdeas.length > 0 && <div className="list" id="comidaList">
                        <div className="list-header">
                            <h3>Comida</h3>
                            {isEditingCom ? <FontAwesomeIcon className="edit-button" onClick={() => setIsEditingCom(prev => !prev)} icon={faX} /> 
                            : <FontAwesomeIcon className="edit-button" onClick={() => toggleEdit('edit-com')} icon={faPenToSquare} />}
                        </div>
                        <ul id="comidaItems">
                            {comidaIdeas.map((item) => (
                                <li key={item.id} className="idea-item">
                                    <span>{item.idea}</span>
                                    {isEditingCom && <div className="buttons">
                                        <button className="complete-button"><FontAwesomeIcon onClick={() => completarIdea('Comida', item.id, item.idea)} icon={faCheck} /></button>
                                        <button className="delete-button"><FontAwesomeIcon onClick={() => eliminarIdea('Comida', item.id)} icon={faXmark} /></button>
                                    </div>}
                                </li>
                            ))}
                        </ul>
                    </div>}
                    {actividadesIdeas.length > 0 && <div className="list" id="actividadesList" style={{marginBottom: '5px'}}>
                        <div className="list-header">
                            <h3>Actividades</h3>
                            {isEditingAct ? <FontAwesomeIcon className="edit-button" onClick={() => setIsEditingAct(prev => !prev)} icon={faX} /> 
                            : <FontAwesomeIcon className="edit-button" onClick={() => toggleEdit('edit-act')} icon={faPenToSquare} />}
                        </div>
                        <ul id="actividadesItems">
                            {actividadesIdeas.map((item) => (
                                <li key={item.id} className="idea-item">
                                    <span>{item.idea}</span>
                                    {isEditingAct && <div className="buttons">
                                        <button className="complete-button"><FontAwesomeIcon onClick={() => completarIdea('Actividades', item.id, item.idea)} icon={faCheck} /></button>
                                        <button className="delete-button"><FontAwesomeIcon onClick={() => eliminarIdea('Actividades', item.id)} icon={faXmark} /></button>
                                    </div>}
                                </li>
                            ))}
                        </ul>
                    </div>}
                </div>

                {(comidaCompletadas.length > 0 || actividadesCompletadas.length > 0) && <div className="text-center ideas-completadas">
                    <button className="btn-viajes-completados-1" onClick={() => navigate("/ideas-completadas")}>Ideas Completadas</button>
                </div>}
            </div>

            {isModalOpen && (
                <Modal onClose={() => setIsModalOpen(false)}>
                    <div className="modal-body">
                        <h2>Introduce la contraseña</h2>
                        <form onSubmit={(e) => { e.preventDefault(); verificarContraseña(); }}>
                            <input
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <button className="botonPrimario" type="submit">
                                Entrar
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default Ideas;
