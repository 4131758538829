// eslint-disable-next-line
import React, { useState, useEffect, Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from './secondary/Modal';
import { doc, Firestore, setDoc, getDoc, collection, getDocs, deleteDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import Alertas from './secondary/Alertas';
import { compare } from 'bcryptjs-react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHeart } from '@fortawesome/free-solid-svg-icons';

interface PhotoMetadata {
    url: string;
    text1: string;
    text2: string;
    createdAt: Date;
}

interface PhotoDocument extends PhotoMetadata {
    id: string;
}

const Galeria = ({ db }: { db: Firestore }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
    const [flipped, setFlipped] = useState<string | null>(null);
    const [photoToDelete, setPhotoToDelete] = useState<PhotoDocument>();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [photos, setPhotos] = useState<PhotoDocument[]>([]);
    const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [startY, setStartY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isDeleteMode, setIsDeleteMode] = useState(false);
    const [pulsarEliminar, setPulsarEliminar] = useState(false);
    const [pulsarSubirFoto, setPulsarSubirFoto] = useState(false);
    const storage = getStorage();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error');
    
    useEffect(() => {
        const checkAccess = () => {
            const hasAccess = sessionStorage.getItem('hasAccess');
            if (hasAccess) {
                setIsAuthenticated(true);
                setIsModalOpen(false);
            }
        };

        const loadPhotos = async () => {
            setLoading(true);
            const fetchedPhotos = await fetchPhotosWithMetadata();
            setPhotos(fetchedPhotos);
            setLoading(false);
        };

        checkAccess();
        loadPhotos();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    // Función para subir la foto y sus metadatos
    const uploadPhotoWithMetadata = async (file: File, text1: string, text2: string): Promise<void> => {
        try {
            const storageRef = ref(storage, `photos/${uuidv4()}`);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Almacenar los metadatos en Firestore
            const docRef = doc(db, "photos", uuidv4());
            await setDoc(docRef, {
                url: downloadURL,
                text1: text1,
                text2: text2,
                createdAt: Timestamp.now()
            });

            setFile(null);
            setText1('');
            setText2('');
            setIsModalUploadOpen(false);
            console.log('Foto subida correctamente.');
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Foto subida correctamente.');
        } catch (error) {
            console.error('Error subiendo la foto: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error subiendo la foto.');
        }
    };

    // Función para obtener las fotos y sus metadatos
    const fetchPhotosWithMetadata = async (): Promise<PhotoDocument[]> => {
        try {
            const querySnapshot = await getDocs(collection(db, "photos"));
            const photos: PhotoDocument[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                photos.push({
                    id: doc.id,
                    url: data.url,
                    text1: data.text1,
                    text2: data.text2,
                    createdAt: data.createdAt.toDate()
                } as PhotoDocument);
            });
            // Ordenar fotos por fecha de creación (del más reciente al más antiguo)
            photos.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
            
            return photos;
        } catch (error) {
            console.error('Error obteniendo las fotos: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error obteniendo las fotos.');
            return [];
        }
    };

    // Función para eliminar la foto y sus metadatos
    const deletePhotoWithMetadata = async (): Promise<void> => {
        try {
            // Eliminar la foto de Firebase Storage
            if (photoToDelete) {
                const storageRef = ref(storage, photoToDelete.url);
                await deleteObject(storageRef);

                // Eliminar los metadatos de Firestore
                const docRef = doc(db, "photos", photoToDelete.id);
                await deleteDoc(docRef);

                setPhotoToDelete(undefined);
                setIsConfirmModalOpen(false);
                console.log('Foto eliminada correctamente.');
                setAlertOpen(true);
                setAlertVariant('success');
                setAlertMessage('Foto eliminada correctamente.');
            }
        } catch (error) {
            console.error('Error eliminanado foto: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error eliminanado foto.');
        }
    };

    const handleUpload = async () => {
        if (file) {
            setLoading(true);
            await uploadPhotoWithMetadata(file, text1, text2);
            const fetchedPhotos = await fetchPhotosWithMetadata();
            setPhotos(fetchedPhotos);
            setLoading(false);
        }
    };

    const fetchPasswordFromDB = async () => {
        const docRef = doc(db, 'config', 'passwordDoc');
        const docSnap = await getDoc(docRef);
        return docSnap.exists() ? docSnap.data().password : '';
    };

    const verifyPassword = async () => {
        const storedPassword = await fetchPasswordFromDB();
        // const hashedInputPassword = CryptoJS.SHA256(password).toString();
        if (!password) {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, introduce una contraseña.');
            setLoading(false);
            return;
        }
        
        setLoading(true);
        const isMatch = await compare(password, storedPassword);
        if (isMatch) {
            sessionStorage.setItem('hasAccess', 'true');
            setIsAuthenticated(true);
            setIsModalOpen(false);
            setPassword('');

            if (pulsarEliminar) {
                setIsDeleteMode(true);
            } else if (pulsarSubirFoto) {
                setIsModalUploadOpen(true);
            }

            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Contraseña correcta. ¡Bienvenido!');
        } else {
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Contraseña incorrecta. Por favor, inténtalo de nuevo.');
        }
        setLoading(false);
    };

    const cerrarModal = () => {
        setIsModalOpen(false);
        window.history.back();
    }

    const handleClick = (index: string) => {
        setFlipped(flipped === index ? null : index);
    };

    const handleDelete = async (event: React.MouseEvent<SVGSVGElement>, photo: PhotoDocument) => {
        event.stopPropagation();
        setPhotoToDelete(photo);
        setIsConfirmModalOpen(true);
    };

    const confirmDeletePhoto = async () => {
        if (!photoToDelete) return;
        setLoading(true);
        await deletePhotoWithMetadata();
        const fetchedPhotos = await fetchPhotosWithMetadata();
        setPhotos(fetchedPhotos);
        setLoading(false);
    };

    // Función para cancelar la eliminación
    const cancelDeletePhoto = () => {
        setPhotoToDelete(undefined);
        setIsConfirmModalOpen(false);
    };

    const cerrarModalUpload = () => {
        setIsModalUploadOpen(false);
    }

    const handleTouchStart = (e: React.TouchEvent) => {
        setStartY(e.touches[0].clientY);
        setIsDragging(true);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging) return;

        const currentY = e.touches[0].clientY;
        if (currentY - startY < -50) {
            setIsBoxOpen(true);
            setIsDragging(false);
        } else if (currentY - startY > 50) {
            setIsBoxOpen(false);
            setIsDragging(false);
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const handleEliminarFoto = () => {
        setPulsarSubirFoto(false);
        setPulsarEliminar(true);
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setIsModalOpen(true);
            return;
        }
        setIsDeleteMode(true);
    }

    const handleCancelarEliminarFoto = () => {
        setPulsarEliminar(false);
        setIsDeleteMode(false);
    }

    const handleSubirFoto = () => {
        setPulsarEliminar(false)
        setIsDeleteMode(false);
        setPulsarSubirFoto(true);
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setIsModalOpen(true);
            return;
        }
        setIsModalUploadOpen(true);
    }

    return (
        <Fragment>
            {loading && (
                <div className="spinner-overlay">
                    <Spinner className="custom-spinner" animation="border" />
                </div>
            )}
            {isModalOpen && (
                <Modal onClose={cerrarModal}>
                    <div className="modal-body">
                        <h2>Introduce la contraseña</h2>
                        <form onSubmit={(e) => { e.preventDefault(); verifyPassword(); }}>
                            <input
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <button className="botonPrimarioGaleria" type="submit">
                                Entrar
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
            {isAuthenticated && (
                <div className="galeria">
                    {photos.map((photo) => (
                        <div key={photo.id} className="foto-item">
                            <div className={`foto-container ${flipped === photo.id ? 'flipped' : ''}`}
                                onClick={() => handleClick(photo.id)}>
                            <img src={photo.url} alt={`Foto ${photo.id}`} className="foto" />
                                <div className="foto-back">
                                    <p>{photo.text1}</p>
                                    <p style={{fontWeight: "bold"}}>{photo.text2}</p>
                                </div>
                                {isDeleteMode ? <FontAwesomeIcon className="delete-button-galeria" onClick={(e) => handleDelete(e, photo)} icon={faXmark} /> : '' }
                            </div>
                        </div>
                    ))}
                    <div className={`botones-galeria ${isBoxOpen ? 'open' : 'closed'}`}
                        onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                        {isBoxOpen ? <FontAwesomeIcon className="toggle-button" icon={faChevronDown} onClick={() => setIsBoxOpen(!isBoxOpen)} /> 
                            : <FontAwesomeIcon className="toggle-button" icon={faChevronUp} onClick={() => setIsBoxOpen(!isBoxOpen)} />}
                        <button className="botonPrimarioGaleria" onClick={() => handleSubirFoto()}>Subir Foto</button>
                        {isDeleteMode ? <button className="botonDeleteFotoGaleria" onClick={() => handleCancelarEliminarFoto()}>Cancelar</button>
                            : <button className="botonDeleteFotoGaleria" onClick={() => handleEliminarFoto()}>Eliminar Foto</button>}
                        {/* <button className="bg-danger" onClick={() => setIsModalUploadOpen(true)}>🤟</button> */}
                    </div>
                </div>
            )}
            {isConfirmModalOpen && (
                <Modal onClose={cancelDeletePhoto}>
                    <div className="modal-body">
                    <h2>Confirmar Eliminación</h2>
                    <p>¿Estás seguro de que deseas eliminar esta foto? </p>
                    <p>Esta acción no se puede deshacer.</p>
                        <div className="row">
                            <div className="col-6">
                                <button className="btn btn-secondary btn-block" onClick={cancelDeletePhoto}>Cancelar</button>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-danger btn-block" onClick={confirmDeletePhoto}>Eliminar</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {isModalUploadOpen && (
                <Modal onClose={() => cerrarModalUpload()}>
                    <div className="modal-body">
                        <h2>Subir una foto</h2>
                        <form onSubmit={(e) => { e.preventDefault(); handleUpload(); }}>
                            <input type="file" onChange={(e) => setFile(e.target.files?.[0] || null)} />
                            <input type="text" placeholder="Fecha" value={text1} onChange={(e) => setText1(e.target.value)} />
                            <input type="text" placeholder="Ciudad" value={text2} onChange={(e) => setText2(e.target.value)} />
                            <button className="botonPrimarioGaleria" type="submit">Subir Foto</button>
                        </form>
                    </div>
                </Modal>
            )}
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default Galeria;