import React, { Fragment, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style.css';
import { Link } from "react-router-dom";

const Home = (props: any) => {

    useEffect(() => {

    }, []);

    return (
        <Fragment>
            <div className="container-fluid main home">
                <div className="div-texto-home text-center" style={{ zIndex: -1000 }}>
                    <h1 className="p-4 pb-0">Morris y Luna</h1>
                    {/* <p className="p-4 pt-0">Bueno, la de Morris y Luna</p> */}
                </div>
                <div className="buttons-div d-flex justify-content-center align-items-center">
                    <div className="container button-container">
                        <div className="row justify-content-center">
                            <div className="col-3 d-block d-md-none"></div>
                            <div className="col-6 col-md-4 mb-2 mb-md-0">
                                <Link to="/viajes">
                                    <button className="boton-viajes">VIAJES</button>
                                </Link>
                            </div>
                            <div className="col-3 d-block d-md-none"></div>
                            <div className="col-6 col-md-4 mr-2 mr-md-0">
                                <Link to="/ideas">
                                    <button className="boton-ideas">IDEAS</button>
                                </Link>
                            </div>
                            <div className="col-6 col-md-4 ml-2 ml-md-0">
                                <Link to="/galeria">
                                    <button className="boton-galeria">GALERÍA</button>
                                </Link>
                            </div>
                            <div className="col-6 col-md-4 mt-2 mt-md-0">
                                <Link to="/ranking">
                                    <button className="boton-ranking">RANKING</button>
                                </Link>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6 col-md-4 mr-2 mr-md-0">
                                <Link to="/calculadora-forex">
                                    <button className="boton-utilidades">UTILIDADES</button>
                                </Link>
                            </div>
                            <div className="col-6 col-md-4 ml-2 ml-md-0">
                                <Link to="/otros">
                                    <button className="boton-otros">OTROS</button>
                                </Link>
                            </div>
                            {/* DESCOMENTAR CUANDO SE IMPLEMENTEN MÁS BOTONES */}
                            <div className="col-6 col-md-4 mt-2 mt-md-0">
                                <Link to="/recetas">
                                    <button className="boton-recetas">RECETAS</button>
                                </Link>
                            </div>
                            {/* <div className="col-6 col-md-4 mt-2 mt-md-0">
                                <Link to="/ranking">
                                    <button className="boton-ranking">RANKING</button>
                                </Link>
                            </div> */}
                        </div>

                        {/* <div className="button-home-div">
                            <Link to="/ideas">
                                <button className="button-home px-5 py-2">IDEAS</button>
                            </Link>
                        </div>
                        <div className="button-home-div">
                            <Link to="/galeria">
                                <button className="button-home px-5 py-2">GALERÍA</button>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Home;