import React, { Fragment, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../style.css";
import Modal from "./Modal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteDoc, doc, Firestore } from "firebase/firestore";
import Alertas from "./Alertas";
import { Spinner } from "react-bootstrap";

interface CartaRecetaProps {
  db: Firestore;
  id: string;
  imagenUrl: string;
  nombre: string;
  ingredientes: string[];
  categoria: string;
  apuntes?: string;
  isGridView?: boolean;
  onDeleteReceta: (id: string) => void;
}

const CartaReceta: React.FC<CartaRecetaProps> = ({
  db,
  id,
  imagenUrl,
  nombre,
  ingredientes,
  categoria,
  apuntes,
  isGridView = false,
  onDeleteReceta,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState<
    "success" | "error" | "warning" | "info"
  >("error");
  const [loading, setLoading] = useState(false);

  const handleShowModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDelete = async (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setIsConfirmModalOpen(true);
  };

  const cancelDeleteReceta = () => {
    setIsConfirmModalOpen(false);
  };

  const confirmDeleteReceta = async () => {
    console.log(id);

    if (id) {
      setLoading(true);
      try {
        await deleteDoc(doc(db, "recetas", id));
        onDeleteReceta(id);
        setAlertVariant("success");
        setAlertMessage(`La receta "${nombre}" ha sido eliminada.`);
        setAlertOpen(true);
      } catch (error) {
        console.error("Error al eliminar la receta:", error);
        setAlertOpen(true);
        setAlertVariant("error");
        setAlertMessage("Error al eliminar la receta.");
      } finally {
        setIsConfirmModalOpen(false);
        setLoading(false);
      }
    }
  };

  const checkAccess = (): boolean => {
    return sessionStorage.getItem("hasAccess") === "true";
  };

  return (
    <Fragment>
      {loading && (
        <div className="spinner-overlay">
          <Spinner className="custom-spinner" animation="border" />
        </div>
      )}
      <div
        className="card mb-4"
        style={{ width: "100%" }} // Ocupa todo el ancho de la pantalla
        onClick={handleShowModal}
      >
        <div className="image-container">
          <img
            src={imagenUrl}
            className="card-img-top fixed-image"
            alt={nombre}
            style={{
              width: "100%",
              height: isGridView ? "180px" : "250px",
              overflow: "hidden",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center">{nombre}</h5>
          {!isGridView && (
            <div className="row">
              <div className="col">
                <p className="card-text">
                  <strong>Categoría:</strong> {categoria}
                </p>
              </div>
              {checkAccess() && (
                <div className="col-4 text-end mt-2">
                  <FontAwesomeIcon
                    title="Favoritos"
                    icon={faTrash}
                    onClick={(e) => handleDelete(e)}
                    style={{
                      fontSize: "1.2rem",
                      verticalAlign: "middle",
                      color: "#d13a3a",
                    }}
                  />
                  {/* <FontAwesomeIcon
                    title="Favoritos"
                    icon={faHeart}
                    style={{
                      paddingLeft: "15px",
                      fontSize: "1.3rem",
                      verticalAlign: "middle",
                      color: "#d13a3a",
                    }}
                  /> */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isConfirmModalOpen && (
        <Modal onClose={cancelDeleteReceta}>
          <div className="modal-body">
            <h2>Confirmar Eliminación</h2>
            <p>¿Estás seguro de que deseas eliminar esta receta? </p>
            <p>Esta acción no se puede deshacer.</p>
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-secondary btn-block"
                  onClick={cancelDeleteReceta}
                >
                  Cancelar
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-danger btn-block"
                  onClick={confirmDeleteReceta}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <div className="modal-body" style={{ padding: 0 }}>
            <div className="image-container">
              <img
                src={imagenUrl}
                className="img-fluid fixed-image"
                alt={nombre}
              />
            </div>
            <h3 className="text-center pt-3 mb-1">
              <strong>{nombre}</strong>
            </h3>
            <p className="text-center">{categoria}</p>
            <div className={`${apuntes ? "mb-4" : ""}`}>
              <p className="mb-2">
                <strong>Ingredientes:</strong>
              </p>
              <div className="row">
                {ingredientes.map((ingrediente, index) => (
                  <div className="col-6" key={index}>
                    <li className="ps-2">{ingrediente}</li>
                  </div>
                ))}
              </div>
            </div>
            {apuntes && (
              <>
                <p className="mb-2">
                  <strong>Notas:</strong>
                </p>
                <p>{apuntes}</p>
              </>
            )}
          </div>
        </Modal>
      )}
      <Alertas
        message={alertMessage}
        variant={alertVariant}
        duration={3000}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      />
    </Fragment>
  );
};

export default CartaReceta;
