import React from "react";

interface LetterGridProps {
    grid: string[][];
    onLetterClick: (row: number, col: number) => void;
    foundCells: [number, number][]; // Celdas de las palabras encontradas
    selectedCells: [number, number][]; // Celdas seleccionadas
}

const LetterGrid: React.FC<LetterGridProps> = ({ grid, onLetterClick, foundCells, selectedCells }) => {
    const isCellFound = (row: number, col: number) => {
        return foundCells.some(([r, c]) => r === row && c === col);
    };

    const isCellSelected = (row: number, col: number) => {
        return selectedCells.some(([r, c]) => r === row && c === col);
    };

    return (
        <div className="grid">
            {grid.map((row, rowIndex) => (
                <div key={rowIndex} className="grid-row">
                    {row.map((letter, colIndex) => (
                        <div key={colIndex} 
                            className={`grid-cell ${isCellFound(rowIndex, colIndex) ? "found" : ""} 
                            ${isCellSelected(rowIndex, colIndex) ? "selected" : ""}`} 
                            onClick={() => onLetterClick(rowIndex, colIndex)}>
                            {letter}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default LetterGrid;
