import React from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Otros: React.FC = () => {
    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <div className="mb-5">
                <Link to="/ganador-aleatorio">
                    <button className="boton-ideas">GANADOR ALEATORIO</button>
                </Link>
            </div>
            <div className="mb-5">
                <Link to="/sopa-de-letras">
                    <button className="boton-ranking">SOPA DE LETRAS</button>
                </Link>
            </div>
            <div className="mb-5">
                <Link to="/calculadora-forex">
                    <button className="boton-utilidades">CALCULADORA FOREX</button>
                </Link>
            </div>
        </Container>
    );
};

export default Otros;
