import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import Home from './components/Home';
import Ideas from './components/Ideas';
import IdeasCompletadas from './components/IdeasCompletadas';
import GaleriaFotos from './components/GaleriaFotos';
import Ranking from './components/Ranking';
import Viajes from './components/Viajes';
import ViajesCompletados from './components/ViajesCompletados';
import SopaDeLetras from './components/SopaDeLetras';
import CalculadoraForex from './components/CalculadoraForex';
import GanadorAleatorio from './components/GanadorAleatorio';
import Otros from './components/Otros';
import Recetas from './components/Recetas';
// import Menu from './components/Menu';

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBZwhjdM74EvgWYmB7GmHWGZW2c1WQz2uM",
  authDomain: "morrisyluna.firebaseapp.com",
  projectId: "morrisyluna",
  storageBucket: "morrisyluna.appspot.com",
  messagingSenderId: "871345265853",
  appId: "1:871345265853:web:b8608665f61dd5a5e22bd4",
  measurementId: "G-T5TL4ZV0R7"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

class App extends React.Component {
  render() {
      return (
          <Fragment>
              {/* <Menu /> */}
              <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/ideas" element={<Ideas db={db}/>} />
                    <Route path="/ideas-completadas" element={<IdeasCompletadas db={db}/>} />
                    <Route path="/galeria" element={<GaleriaFotos db={db}/>} />
                    <Route path="/ranking" element={<Ranking db={db}/>} />
                    <Route path="/viajes" element={<Viajes db={db}/>} />
                    <Route path="/viajes-completados" element={<ViajesCompletados db={db}/>} />
                    <Route path="/sopa-de-letras" element={<SopaDeLetras db={db} />} />
                    <Route path="/calculadora-forex" element={<CalculadoraForex />} />
                    <Route path="/ganador-aleatorio" element={<GanadorAleatorio />} />
                    <Route path="/otros" element={<Otros />} />
                    <Route path="/recetas" element={<Recetas db={db} />} />
                  </Routes>
              </BrowserRouter>
          </Fragment>
      )
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);