/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';

interface RuletaProps {
    options: string[];
    startAngle: number;
    isSpinning: boolean;
    haGirado: boolean; // Nueva prop para saber si ha girado
    onSpinEnd: (winner: string) => void;
}

const Ruleta: React.FC<RuletaProps> = ({ options, startAngle, isSpinning, haGirado, onSpinEnd }) => {
    const arc = Math.PI / (options.length / 2);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const ctx = useRef<CanvasRenderingContext2D | null>(null);

    const byte2Hex = (n: number) => {
        const nybHexString = "0123456789ABCDEF";
        return String(nybHexString.substr((n >> 4) & 0x0F, 1)) + nybHexString.substr(n & 0x0F, 1);
    };

    const RGB2Color = (r: number, g: number, b: number) => {
        return '#' + byte2Hex(r) + byte2Hex(g) + byte2Hex(b);
    };

    const getColor = (item: number, maxitem: number) => {
        const phase = 0;
        const center = 128;
        const width = 127;
        const frequency = Math.PI * 2 / maxitem;

        const red = Math.sin(frequency * item + 2 + phase) * width + center;
        const green = Math.sin(frequency * item + 0 + phase) * width + center;
        const blue = Math.sin(frequency * item + 4 + phase) * width + center;

        return RGB2Color(red, green, blue);
    };

    const drawRouletteWheel = () => {
        const canvas = canvasRef.current;
        if (canvas && ctx.current) {
            const outsideRadius = getScreenWidth() > 400 ? 200 : 200 - 15;
            const textRadius = getScreenWidth() > 400 ? 160 : 160 - 15;
            const insideRadius = getScreenWidth() > 400 ? 125 : 125 - 15;

            ctx.current.clearRect(0, 0, getScreenWidth(), getScreenWidth());
            ctx.current.strokeStyle = "black";
            ctx.current.lineWidth = 2;
            ctx.current.font = 'bold 12px Helvetica, Arial';

            for (let i = 0; i < options.length; i++) {
                const angle = startAngle + i * arc;
                ctx.current.fillStyle = getColor(i, options.length);

                ctx.current.beginPath();
                ctx.current.arc(getScreenWidth() / 2, getScreenWidth() / 2, outsideRadius, angle, angle + arc, false);
                ctx.current.arc(getScreenWidth() / 2, getScreenWidth() / 2, insideRadius, angle + arc, angle, true);
                ctx.current.stroke();
                ctx.current.fill();

                ctx.current.save();
                ctx.current.shadowOffsetX = -1;
                ctx.current.shadowOffsetY = -1;
                ctx.current.shadowBlur = 0;
                ctx.current.shadowColor = "rgb(220,220,220)";
                ctx.current.fillStyle = "black";
                ctx.current.translate(getScreenWidth() / 2 + Math.cos(angle + arc / 2) * textRadius,
                    getScreenWidth() / 2 + Math.sin(angle + arc / 2) * textRadius);
                ctx.current.rotate(angle + arc / 2 + Math.PI / 2);
                const text = options[i];
                ctx.current.fillText(text, -ctx.current.measureText(text).width / 2, 0);
                ctx.current.restore();
            }

            // Flecha
            ctx.current.fillStyle = "black";
            ctx.current.beginPath();
            ctx.current.moveTo(getScreenWidth() / 2 - 4, getScreenWidth() / 2 - (outsideRadius + 5));
            ctx.current.lineTo(getScreenWidth() / 2 + 4, getScreenWidth() / 2 - (outsideRadius + 5));
            ctx.current.lineTo(getScreenWidth() / 2 + 4, getScreenWidth() / 2 - (outsideRadius - 5));
            ctx.current.lineTo(getScreenWidth() / 2 + 9, getScreenWidth() / 2 - (outsideRadius - 5));
            ctx.current.lineTo(getScreenWidth() / 2 + 0, getScreenWidth() / 2 - (outsideRadius - 13));
            ctx.current.lineTo(getScreenWidth() / 2 - 9, getScreenWidth() / 2 - (outsideRadius - 5));
            ctx.current.lineTo(getScreenWidth() / 2 - 4, getScreenWidth() / 2 - (outsideRadius - 5));
            ctx.current.lineTo(getScreenWidth() / 2 - 4, getScreenWidth() / 2 - (outsideRadius + 5));
            ctx.current.fill();
        }
    };

    const showWinner = (winner: string) => {
        if (ctx.current) {
            ctx.current.save();
            ctx.current.font = 'bold 30px Helvetica, Arial';
            ctx.current.fillStyle = 'black';
            ctx.current.fillText(winner, getScreenWidth() / 2 - ctx.current.measureText(winner).width / 2, getScreenWidth() / 2 + 10);
            ctx.current.restore();
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            ctx.current = canvas.getContext('2d');
            drawRouletteWheel();
        }
    }, [startAngle, options]);

    useEffect(() => {
        if (!ctx.current || isSpinning || !haGirado) return; // Solo proceder si el giro ha terminado y ha girado al menos una vez

        const degrees = startAngle * 180 / Math.PI + 90;
        const arcd = (Math.PI / (options.length / 2)) * 180 / Math.PI;
        const index = Math.floor((360 - degrees % 360) / arcd);
        const ganador = options[index];

        // Solo mostrar el ganador y llamar a onSpinEnd si el giro terminó y ha girado
        if (!isSpinning && haGirado) {
            showWinner(ganador);
            onSpinEnd(ganador);
        }
    }, [isSpinning, startAngle, options, haGirado, onSpinEnd]);

    const getScreenWidth = (): number => {
        return window.innerWidth;
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px'
        }}>
            <canvas id="canvas" ref={canvasRef} width={getScreenWidth()} height={getScreenWidth()}></canvas>
        </div>
    );
};

export default Ruleta;
