/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from 'react';
import { collection, Firestore, getDocs } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style.css';
import Alertas from './secondary/Alertas';

interface Idea {
    id: string;
    idea: string;
}

const IdeasCompletadas = ({ db }: { db: Firestore })=> {
    const [comidaCompletadas, setComidaCompletadas] = useState<Idea[]>([]);
    const [actividadesCompletadas, setActividadesCompletadas] = useState<Idea[]>([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error');

    const mostrarIdeasCompletadas = async () => {
        try {
            const comidaCompletadasSnapshot = await getDocs(collection(db, 'Comidas Completadas'));
            const comidaCompletadasList: Idea[] = [];
            comidaCompletadasSnapshot.forEach(doc => {
                comidaCompletadasList.push({ id: doc.id, idea: doc.data().idea });
            });
            setComidaCompletadas(comidaCompletadasList);

            const actividadesCompletadasSnapshot = await getDocs(collection(db, 'Actividades Completadas'));
            const actividadesCompletadasList: Idea[] = [];
            actividadesCompletadasSnapshot.forEach(doc => {
                actividadesCompletadasList.push({ id: doc.id, idea: doc.data().idea });
            });
            setActividadesCompletadas(actividadesCompletadasList);
        } catch (error) {
            console.error('Error obteniendo las ideas completadas: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error obteniendo las ideas completadas.');
        }
    };

    useEffect(() => {
        mostrarIdeasCompletadas();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid main">
                <div className="text-center">
                    <h1 className="p-4 pb-3">Ideas Completadas</h1>
                </div>

                <div className="container-fluid content">
                    {comidaCompletadas.length > 0 && <div className="list" id="comidaCompletadasList">
                        <h3>Comidas</h3>
                        <ul id="comidaCompletadasItems">
                            {comidaCompletadas.map((item) => (
                                <li key={item.id}>{item.idea}</li>
                            ))}
                        </ul>
                    </div>}

                    {actividadesCompletadas.length > 0 && <div className="list" id="actividadesCompletadasList">
                        <h3>Actividades</h3>
                        <ul id="actividadesCompletadasItems">
                            {actividadesCompletadas.map((item) => (
                                <li key={item.id}>{item.idea}</li>
                            ))}
                        </ul>
                    </div>}
                </div>
            </div>
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default IdeasCompletadas;
