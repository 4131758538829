/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from 'react';
import { collection, Firestore, getDocs } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style.css';
import Alertas from './secondary/Alertas';

interface Viaje {
    id: string;
    destino: string;
}

const ViajesCompletados = ({ db }: { db: Firestore })=> {
    const [viajesCompletados, setViajesCompletados] = useState<Viaje[]>([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error');

    const mostrarViajesCompletados = async () => {
        try {
            const viajesCompletadosSnapshot = await getDocs(collection(db, 'Viajes Completados'));
            const viajesCompletadosList: Viaje[] = [];
            viajesCompletadosSnapshot.forEach(doc => {
                viajesCompletadosList.push({ id: doc.id, destino: doc.data().destino });
            });
            setViajesCompletados(viajesCompletadosList);
        } catch (error) {
            console.error('Error obteniendo los viajes completados: ', error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error obteniendo los viajes completados.');
        }
    };

    useEffect(() => {
        mostrarViajesCompletados();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid main">
                <div className="text-center">
                    <h1 className="p-4 pb-3">Viajes Completados</h1>
                </div>

                <div className="container-fluid content">
                    {viajesCompletados.length > 0 && <div className="list" id="viajesCompletadosList">
                        <ul id="comidaCompletadasItems">
                            {viajesCompletados.map((item) => (
                                <li key={item.id}>{item.destino}</li>
                            ))}
                        </ul>
                    </div>}
                </div>
            </div>
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default ViajesCompletados;
