/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from "react";
import { collection, addDoc, getDocs, deleteDoc, doc, Firestore, getDoc } from "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPenToSquare, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import Alertas from "./secondary/Alertas";
import Modal from "./secondary/Modal";
import { useNavigate } from "react-router-dom";
import { compare } from 'bcryptjs-react';

interface Viaje {
    id: string;
    destino: string;
}

const Viajes = ({ db }: { db: Firestore }) => {
    const [viaje, setViaje] = useState("");
    const [viajes, setViajes] = useState<Viaje[]>([]);
    const [completados, setCompletados] = useState<Viaje[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [modalAction, setModalAction] = useState<'save' | 'edit'>('save');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error');
    const navigate = useNavigate();

    const mostrarViajes = async () => {
        setLoading(true);
        try {
            const viajesSnapshot = await getDocs(collection(db, "Viajes"));
            const viajesList: Viaje[] = [];
            viajesSnapshot.forEach((doc) => {
                viajesList.push({ id: doc.id, destino: doc.data().destino });
            });
            setViajes(viajesList);

            const completadosSnapshot = await getDocs(collection(db, "Viajes Completados"));
            const completadosList: Viaje[] = [];
            completadosSnapshot.forEach((doc) => {
                completadosList.push({ id: doc.id, destino: doc.data().destino });
            });
            setCompletados(completadosList);
        } catch (error) {
            console.error("Error obteniendo los viajes: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error obteniendo la idea.');
        } finally {
            setLoading(false);
        }
    };

    const guardarViaje = async () => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setModalAction('save');
            setIsModalOpen(true);
            return;
        }

        if (!viaje) {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, ingresa un destino.');
            return;
        }

        try {
            await addDoc(collection(db, "Viajes"), { destino: viaje });
            setViaje("");

            if (viajes.length === 0) {
                setIsEditing(false);
            }

            mostrarViajes();
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Viaje guardado correctamente.');
        } catch (error) {
            console.error("Error guardando el viaje: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error guardando el viaje.');
        }
    };

    const verificarContraseña = async () => {
        const docRef = doc(db, 'config', 'passwordDoc');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const storedPassword = docSnap.data().password;
            if (!password) {
                setAlertOpen(true);
                setAlertVariant('info');
                setAlertMessage('Por favor, introduce una contraseña.');
                return;
            }

            setLoading(true);
            const isMatch = await compare(password, storedPassword);
            if (isMatch) {
                sessionStorage.setItem('hasAccess', 'true');
                setIsModalOpen(false);
                if (modalAction === 'save') {
                    guardarViaje();
                } else {
                    toggleEdit();
                }

                setPassword('');
                setAlertOpen(true);
                setAlertVariant('success');
                setAlertMessage('Contraseña correcta. ¡Bienvenido!');
            } else {
                setAlertOpen(true);
                setAlertVariant('error');
                setAlertMessage('Contraseña incorrecta. Por favor, inténtalo de nuevo.');
            }
        } else {
            console.error('No se encontró el documento de contraseña.');
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('No se encontró el documento de contraseña.');
        }
        setLoading(false);
    };

    const completarViaje = async (id: string, destino: string) => {
        try {
            await addDoc(collection(db, "Viajes Completados"), { destino });
            await deleteDoc(doc(db, "Viajes", id));
            mostrarViajes();
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('El viaje "' + destino + '" se ha realizado con éxito.');
        } catch (error) {
            console.error("Error completando el viaje: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error completando el viaje.');
        }
    };

    const eliminarViaje = async (id: string) => {
        try {
            await deleteDoc(doc(db, "Viajes", id));
            mostrarViajes();
            setAlertVariant('success');
            setAlertMessage('Viaje eliminado correctamente.');
        } catch (error) {
            console.error("Error eliminando el viaje: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error eliminando el viaje.');
        }
    };

    const toggleEdit = () => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setModalAction('edit');
            setIsModalOpen(true);
            return;
        }

        setIsEditing(prev => !prev);
    };

    useEffect(() => {
        mostrarViajes();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid main">
                {loading && (
                    <div className="spinner-overlay">
                        <Spinner className="custom-spinner" animation="border" />
                    </div>
                )}
                
                <div className="text-center">
                    <h1 className="pb-3">Lista de Viajes</h1>
                </div>

                <div className="container-fluid content pb-3">
                    <form onSubmit={(e) => { e.preventDefault(); guardarViaje(); }}>
                        <div className="input-group">
                            <label htmlFor="viaje">Ingresa un destino</label>
                            <input type="text" id="viaje" value={viaje} onChange={(e) => setViaje(e.target.value)} style={{marginRight: '0'}}/>
                        </div>
                        <button className="botonPrimarioViajes" type="submit">Guardar Viaje</button>
                    </form>

                    {viajes.length > 0 && <div className="list" id="comidaList">
                        <div className="list-header">
                            <h3>Viajes Pendientes / Ideas</h3>
                            {isEditing ? <FontAwesomeIcon className="edit-button" onClick={() => setIsEditing(prev => !prev)} icon={faX} /> 
                            : <FontAwesomeIcon className="edit-button" onClick={() => toggleEdit()} icon={faPenToSquare} />}
                        </div>
                        <ul id="comidaItems">
                            {viajes.map((item) => (
                                <li key={item.id} className="idea-item">
                                    <span>{item.destino}</span>
                                    {isEditing && <div className="buttons">
                                        <button className="complete-button"><FontAwesomeIcon onClick={() => completarViaje(item.id, item.destino)} icon={faCheck} /></button>
                                        <button className="delete-button"><FontAwesomeIcon onClick={() => eliminarViaje(item.id)} icon={faXmark} /></button>
                                    </div>}
                                </li>
                            ))}
                        </ul>
                    </div>}

                    {(completados.length > 0) && <div className="text-center ideas-completadas">
                        <button className={viajes.length > 0 ? "btn-viajes-completados-1" : "btn-viajes-completados-2"}
                            onClick={() => navigate("/viajes-completados")}>Viajes Completados</button>
                    </div>}
                </div>
                
            </div>

            {isModalOpen && (
                <Modal onClose={() => setIsModalOpen(false)}>
                    <div className="modal-body">
                        <h2>Introduce la contraseña</h2>
                        <form onSubmit={(e) => { e.preventDefault(); verificarContraseña(); }}>
                            <input
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                            <button className="botonPrimarioViajes" type="submit">
                                Entrar
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
            <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </Fragment>
    );
};

export default Viajes;
