/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import Modal from "./secondary/Modal";
import { collection, getDocs, addDoc, doc, updateDoc, Firestore, getDoc, deleteDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faPenToSquare, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from 'react-bootstrap';
import Alertas from "./secondary/Alertas";
import { compare } from 'bcryptjs-react';

interface Item {
    id: string;
    name: string;
}

interface Category {
    id: string;
    name: string;
    items: Item[];
    isEditing: boolean;
}

const Ranking = ({ db }: { db: Firestore }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newItemName, setNewItemName] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [openCategoryModalAfterPassword, setOpenCategoryModalAfterPassword] = useState(false);
    const [editingCategories, setEditingCategories] = useState<Set<string>>(new Set());
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState<string | null>(null);
    const [pendingDrag, setPendingDrag] = useState<{ result: DropResult, categoryId: string } | null>(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState<'success' | 'error' | 'warning' | 'info'>('error');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const categoriesSnapshot = await getDocs(collection(db, "categories"));
                const categoriesData = categoriesSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as Category[];
                setCategories(categoriesData);
                setSelectedCategory(categoriesData[0].id);
            } catch (error) {
                console.error("Error buscando categorias: ", error);
                setAlertOpen(true);
                setAlertVariant('error');
                setAlertMessage('Error buscando categorias.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSaveCategory = async () => {
        if (newCategoryName.trim() === "") {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, ingresa una nueva categoria.');
            return;
        }

        const newCategory = {
            name: newCategoryName,
            items: [],
            isEditing: false
        };

        setLoading(true);
        try {
            const docRef = await addDoc(collection(db, "categories"), newCategory);
            setCategories([...categories, { ...newCategory, id: docRef.id }]);
            setSelectedCategory(docRef.id);
            setNewCategoryName("");
            setIsModalOpen(false);
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Categoría añadida correctamente.');
        } catch (error) {
            console.error("Error añadiendo categoria: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error añadiendo categoría.');
        } finally {
            setLoading(false);
        }
    };

    const handleAddItem = async () => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setShowPasswordModal(true);
            return;
        }

        if (newItemName.trim() === "") {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, ingresa un producto.');
            return;
        } else if (selectedCategory === "") {
            setAlertOpen(true);
            setAlertVariant('info');
            setAlertMessage('Por favor, seleccione una categoria.');
            return;
        }

        const categoryIndex = categories.findIndex(
            (cat) => cat.id === selectedCategory
        );
        if (categoryIndex === -1) return;

        const newItem: Item = {
            id: Date.now().toString(),
            name: newItemName,
        };

        const updatedCategories = [...categories];
        updatedCategories[categoryIndex].items.push(newItem);

        setLoading(true);
        try {
            const categoryRef = doc(db, "categories", selectedCategory);
            await updateDoc(categoryRef, {
                items: updatedCategories[categoryIndex].items,
            });
            setCategories(updatedCategories);
            setNewItemName("");
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Producto añadido correctamente.');
        } catch (error) {
            console.error("Error añadiendo producto: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error añadiendo producto.');
        } finally {
            setLoading(false);
        }
    };

    const handleDragEnd = async (result: DropResult, categoryId: string) => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setPendingDrag({ result, categoryId });
            setShowPasswordModal(true);
            return;
        }

        if (!result.destination) return;

        const { source, destination } = result;

        if (!destination) return;

        const newCategories = categories.map((category) => {
            if (category.id === categoryId) {
                const newItems = Array.from(category.items);
                const [movedItem] = newItems.splice(source.index, 1);
                newItems.splice(destination.index, 0, movedItem);

                // Actualiza Firestore
                const categoryRef = doc(db, "categories", categoryId);
                updateDoc(categoryRef, { items: newItems });

                return { ...category, items: newItems };
            }
            return category;
        });

        setCategories(newCategories);
    };

    const verificarContraseña = async () => {
        const docRef = doc(db, 'config', 'passwordDoc');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const storedPassword = docSnap.data().password;
            if (!password) {
                setAlertOpen(true);
                setAlertVariant('info');
                setAlertMessage('Por favor, introduce una contraseña.');
                return;
            }

            setLoading(true);
            const isMatch = await compare(password, storedPassword);
            if (isMatch) {
                sessionStorage.setItem('hasAccess', 'true');
                setShowPasswordModal(false);
                setPassword('');

                // Si hay una acción pendiente, ejecutarla
                if (pendingDrag) {
                    handleDragEnd(pendingDrag.result, pendingDrag.categoryId);
                    setPendingDrag(null);
                }

                if (openCategoryModalAfterPassword) {
                    setIsModalOpen(true);
                    setOpenCategoryModalAfterPassword(false);
                }
                setAlertOpen(true);
                setAlertMessage('Contraseña correcta. ¡Bienvenido!');
                setAlertVariant('success');
            } else {
                setAlertOpen(true);
                setAlertMessage('Contraseña incorrecta. Por favor, inténtalo de nuevo.');
                setAlertVariant('error');
            }
        } else {
            console.error('No se encontró el documento de contraseña.');
            setAlertOpen(true);
            setAlertMessage('No se encontró el documento de contraseña.');
            setAlertVariant('error');
        }
        setLoading(false);
    };

    const checkNuevaCategoria = async () => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setOpenCategoryModalAfterPassword(true);
            setShowPasswordModal(true);
            return;
        }
        setIsModalOpen(true);
    };

    const handleEditCategory = (categoryId: string) => {
        const hasAccess = sessionStorage.getItem('hasAccess');
        if (!hasAccess) {
            setShowPasswordModal(true);
            return;
        }

        setEditingCategories((prevEditingCategories) => {
            const newEditingCategories = new Set(prevEditingCategories);
            if (newEditingCategories.has(categoryId)) {
                newEditingCategories.delete(categoryId);
            } else {
                newEditingCategories.add(categoryId);
            }
            return newEditingCategories;
        });
    };

    const handleDeleteItem = async (categoryId: string, itemId: string) => {
        setLoading(true);
        try {
            // Encuentra la categoría que contiene el ítem
            const categoryIndex = categories.findIndex((cat) => cat.id === categoryId);
            if (categoryIndex === -1) return;

            // Filtra el ítem a eliminar
            const updatedItems = categories[categoryIndex].items.filter((item) => item.id !== itemId);

            // Actualiza Firestore
            const categoryRef = doc(db, "categories", categoryId);
            await updateDoc(categoryRef, { items: updatedItems });

            // Actualiza el estado local
            const updatedCategories = [...categories];
            updatedCategories[categoryIndex].items = updatedItems;
            setCategories(updatedCategories);
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Producto eliminado correctamente.');
        } catch (error) {
            console.error("Error eliminando item: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error eliminando item.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCategory = async (categoryId: string) => {
        setCategoryToDelete(categoryId);
        setIsConfirmModalOpen(true);
    };

    const confirmDeleteCategory = async () => {
        if (!categoryToDelete) return;

        setLoading(true);
        try {
            // Referencia del documento de la categoría a eliminar
            const categoryRef = doc(db, "categories", categoryToDelete);
            await deleteDoc(categoryRef);

            // Actualiza el estado local
            const updatedCategories = categories.filter(category => category.id !== categoryToDelete);
            setCategories(updatedCategories);
            setCategoryToDelete(null);
            setIsConfirmModalOpen(false);
            setAlertOpen(true);
            setAlertVariant('success');
            setAlertMessage('Categoría eliminada correctamente.');
        } catch (error) {
            console.error("Error eliminando categoría: ", error);
            setAlertOpen(true);
            setAlertVariant('error');
            setAlertMessage('Error eliminando categoría.');
        } finally {
            setLoading(false);
        }
    };

    // Función para cancelar la eliminación
    const cancelDeleteCategory = () => {
        setCategoryToDelete(null);
        setIsConfirmModalOpen(false);
    };

    return (
        <Fragment>
            <div className="container-fluid main">

                {loading && (
                    <div className="spinner-overlay">
                        <Spinner className="custom-spinner" animation="border" />
                    </div>
                )}

                <div className="text-center">
                    <h1 className="pb-0">Ranking</h1>
                </div>

                <div className="container-fluid content pb-3">
                    <div
                        className="toggle-form"
                        onClick={() => setIsFormOpen(!isFormOpen)}
                    >
                        {/* <span>{isFormOpen ? 'Ocultar Formulario' : 'Mostrar Formulario'}</span> */}
                        <FontAwesomeIcon
                            icon={isFormOpen ? faChevronUp : faChevronDown}
                            className="icon-toggle"
                        />
                    </div>

                    {isFormOpen && (
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleAddItem();
                        }}
                        >
                            <div className="input-group">
                                <label htmlFor="item">Ingresa el producto</label>
                                <input
                                    type="text"
                                    value={newItemName}
                                    id="item"
                                    onChange={(e) => setNewItemName(e.target.value)}
                                    style={{ marginRight: '0' }}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="category">Selecciona la categoría</label>
                                <select
                                    value={selectedCategory}
                                    id="category"
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    disabled={categories.length === 0}
                                >
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button className="botonPrimarioRanking">Añadir</button>
                            <div className="text-center my-3">
                                <a className="" onClick={checkNuevaCategoria}>
                                    Nueva Categoría
                                </a>
                            </div>
                        </form>
                    )}
                    {categories.length > 0 ? (
                        categories.map((category) => (
                            <div className="list" key={category.id}>
                                <div className="list-header">
                                    <h3 className="pb-2">{category.name}</h3>
                                    {editingCategories.has(category.id) ? <FontAwesomeIcon className="edit-button" onClick={() => handleEditCategory(category.id)} icon={faX} />
                                        : <FontAwesomeIcon id={category.id} className="edit-button" onClick={() => handleEditCategory(category.id)} icon={faPenToSquare} />}
                                </div>
                                <DragDropContext
                                    onDragEnd={(result) => handleDragEnd(result, category.id)}
                                >
                                    <Droppable droppableId={category.id}>
                                        {(provided) => (
                                            <ul ref={provided.innerRef} {...provided.droppableProps}>
                                                {category.items.map((item, index) => (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={item.id}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <li
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`draggable-item ${snapshot.isDragging ? "dragging" : ""
                                                                    }`}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                            >
                                                                {index + 1}. {item.name}
                                                                {editingCategories.has(category.id) && (
                                                                    <div className="buttons">
                                                                        <button className="delete-button"><FontAwesomeIcon onClick={() => handleDeleteItem(category.id, item.id)} icon={faXmark} /></button>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                {editingCategories.has(category.id) ?
                                    <div className="text-center">
                                        <a style={{ color: "red", fontWeight: "bold" }} onClick={() => handleDeleteCategory(category.id)}>
                                            Eliminar Categoría
                                        </a>
                                    </div> : ""}
                            </div>
                        ))
                    ) : ""}
                    {/*
                    // Texto para cuando no hay categorias disponibles
                    ) : (
                        <div className="centered-container">
                        <h3>No hay categorías disponibles.</h3>
                        </div>
                    )} */}
                    {isModalOpen && (
                        <Modal onClose={() => setIsModalOpen(false)}>
                            <h2>Añadir Nueva Categoría</h2>
                            <form onSubmit={(e) => { e.preventDefault(); handleSaveCategory(); }}>
                                <input
                                    type="text"
                                    value={newCategoryName}
                                    onChange={(e) => setNewCategoryName(e.target.value)}
                                />
                                <button className="botonPrimarioRanking" type="submit">
                                    Añadir
                                </button>
                            </form>
                        </Modal>
                    )}
                </div>
                {showPasswordModal && (
                    <Modal onClose={() => setShowPasswordModal(false)}>
                        <div className="modal-body">
                            <h2>Introduce la contraseña</h2>
                            <form onSubmit={(e) => { e.preventDefault(); verificarContraseña(); }}>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <button className="botonPrimarioRanking" type="submit">
                                    Entrar
                                </button>
                            </form>
                        </div>
                    </Modal>
                )}
                {isConfirmModalOpen && (
                    <Modal onClose={cancelDeleteCategory}>
                        <div className="modal-body">
                            <h2>Confirmar Eliminación</h2>
                            <p>¿Estás seguro de que deseas eliminar esta categoría? </p>
                            <p>Esta acción no se puede deshacer.</p>
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn btn-danger btn-block" onClick={confirmDeleteCategory}>Eliminar</button>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-secondary btn-block" onClick={cancelDeleteCategory}>Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}
                <Alertas message={alertMessage} variant={alertVariant} duration={3000} open={alertOpen} onClose={() => setAlertOpen(false)} />
            </div>
        </Fragment>
    );
};

export default Ranking;
