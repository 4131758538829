import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

interface AlertasProps {
  message: string;
  variant?: 'success' | 'info' | 'warning' | 'error';
  duration?: number;
  onClose?: () => void;
  open: boolean;
  heading?: string;
}

const Alertas: React.FC<AlertasProps> = ({ message, variant = 'info', duration = 3000, onClose, open, heading }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ zIndex: 1301 }}
    >
      <Stack sx={{ alignContent: 'right' }} spacing={2}>
        <Alert severity={variant} onClose={onClose}>
          {heading && <strong>{heading}</strong>}
          {message}
        </Alert>
      </Stack>
    </Snackbar>
  );
};

export default Alertas;
